import Axios from 'axios'
import { Context } from '@nuxt/types'
import { BasicService } from '~/services/BasicService'
import { reverseApi } from '~~/config'
import { onFulfilled, onRejected } from '~/services/interceptors'
import { validateParams } from '~/modules/tools'
import { IKeyInfo } from '~/services/SubAccount'

export interface IReverseInfoParams {
  type?: string,
  key_info: IKeyInfo,
}

export interface IReverseInfoRes {
  account: string,
  is_valid: boolean,
  reverse_status: number,
}

export default class DasReverse extends BasicService {
  constructor (context: Context) {
    super(context)
    this.axios = Axios.create({
      baseURL: reverseApi,
      withCredentials: true
    })
    this.axios.interceptors.response.use(onFulfilled(context), onRejected())
  }

  reverseInfo ({
    type = 'blockchain',
    key_info,
  }: IReverseInfoParams): Promise<IReverseInfoRes> {
    validateParams({ ...key_info }, 'reverseInfo')
    return this.axios.post('/reverse/info', {
      type,
      key_info,
    })
  }
}
