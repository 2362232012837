import { Context } from '@nuxt/types'
import ServiceUpgradeTips from '~/components/ServiceUpgradeTips.vue'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ({ app }: Context, inject: Function) {
  const _instance = new ServiceUpgradeTips({
    propsData: {
      showing: false,
      $tt: app.$tt,
      i18n: app.i18n,
      $services: app.$services
    }
  })
  const _vm = _instance.$mount()
  _instance.$on('close', () => {
    _instance.showing = false
  })
  document.body.appendChild(_vm.$el)
  inject('serviceUpgradeTips', () => {
    _instance.showing = true
  })
}
