import Axios from 'axios'
import { BasicService } from '~/services/BasicService'
import { isProdData } from '~~/config'
import { CoinType } from '~/constant/chain'

export interface IToken {
  coin_type: CoinType,
  contract: string,
  decimals: number,
  name: string,
  price: string,
  symbol: string,
  token_id: string,
  logo?: string,
}

export interface IConfig {
  sale_cell_capacity: number, // CKB that requires pledge for uploading account
  min_sell_price: number, // minimum price for sale
  offer_cell_capacity: number, // minimum amount of quotation
  premium_percentage: string, // only stripe
  premium_base: string, // only stripe
  min_change_capacity: number,
}

export interface IUpgradeNoticeRes {
  [key: string]: { [key: string]: string },
}

export default class Common extends BasicService {
  /**
   * get token list
   */
  tokens (): Promise<{ token_list: IToken[] }> {
    return this.axios.post('/token/list')
  }

  /**
   * get config info
   */
  config (): Promise<IConfig> {
    return this.axios.post('/config/info')
  }

  async upgradeNotice (): Promise<IUpgradeNoticeRes> {
    try {
      const timestamp = Date.now()
      const fileName = isProdData ? 'upgrade-notice' : 'upgrade-notice-test'
      const { data } = await Axios.get(
        `https://dotbit-upgrade-notice.oss-cn-hongkong.aliyuncs.com/upgrade%20notice/${fileName}.json?t=${timestamp}`
      )
      if (data?.end_date) {
        const endTimestamp = new Date(
          `${String(data.end_date)}T24:00:00.000+0800`
        ).getTime()
        return timestamp < endTimestamp ? data : {}
      }
      return {}
    }
    catch (err) {
      console.error(err)
      return {}
    }
  }
}
