module.exports = {
  isProdData: process.env.IS_PROD === 'true',
  appName: process.env.APP_NAME,
  description: process.env.APP_DESCRIPTION,
  hostname: process.env.HOSTNAME,
  domain: process.env.DOMAIN,
  servicesApi: process.env.SERVICES_API,
  subAccountApi: process.env.SUB_ACCOUNT_API,
  dpApi: process.env.DP_API,
  identiconServer: process.env.IDENTICON_SERVER,
  appDaSystems: process.env.APP_DA_SYSTEMS,
  dotbitBalance: process.env.DOTBIT_BALANCE,
  dpPageUrl: process.env.DP_PAGE_URL,
  superDid: process.env.SUPER_DID,
  ckbNode: process.env.CKB_NODE,
  reverseApi: process.env.REVERSE_API,
  subdidApp: process.env.SUBDID_APP,
  fiatTermsLink: process.env.FIAT_TERMS_LINK,
  nuxtCdnPublicPath: process.env.NUXT_CDN_PUBLIC_PATH,
  baseUrl: process.env.BASE_URL,
}
