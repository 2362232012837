import { Context } from '@nuxt/types'
import Axios from 'axios'
import { onFulfilled, onRejected } from '~/services/interceptors'
import { dpApi } from '~~/config'
import { BasicService } from './BasicService'
import { SignTxListRes, SignTxListParams } from 'wallet-bridge'

export enum ACTION_DP {
  TRANSFER_TLDID = 'transfer_tldid',
  TRANSFER_SLID = 'transfer_sldid',
  TRANSFER_COUPON = 'transfer_coupon',
}
export interface IKeyInfo {
  coin_type: string,
  key: string,
}
export interface ISignListItem {
  sign_type: number,
  sign_msg: string,
}

export interface ITransferParams {
  type: string,
  key_info: IKeyInfo,
  amount_dp: string,
  order_id: string, // business order number, memo
  action_dp: ACTION_DP, // payment scenario transfer_tldid, transfer_sldid, transfer_coupon
}

export interface ISendRes {
  hash: string,
}

export interface IReportStripeErrorParams {
  order_id: string,
  type: string,
  code?: string,
  decline_code?: string,
  doc_url?: string,
  message?: string,
}

export default class DidPoint extends BasicService {
  constructor (context: Context) {
    super(context)
    this.axios = Axios.create({
      baseURL: dpApi,
      withCredentials: true,
    })
    this.axios.interceptors.response.use(onFulfilled(context), onRejected())
  }

  transfer (params: ITransferParams): Promise<SignTxListParams> {
    return this.axios.post('/dp/transfer', params)
  }

  send (params: SignTxListRes): Promise<ISendRes> {
    return this.axios.post('/tx/send', params)
  }

  /**
   * Report a Stripe error
   * @param params
   */
  reportStripeError (params: IReportStripeErrorParams): Promise<void> {
    return this.axios.post('/stripe/err', params)
  }
}
