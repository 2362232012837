import { Context } from '@nuxt/types'
import { Wallet } from 'wallet-bridge'
import { isProdData } from '~~/config'
import { onGlobalSetup, provide } from '@nuxtjs/composition-api'
import { ME_KEYS } from '~/store/me'
import { bsc, bscTestnet, holesky, mainnet as ethereum, polygon, polygonMumbai } from '@wagmi/core/chains'
import { createConfig, http } from '@wagmi/core'
import { injected, walletConnect } from '@wagmi/connectors'
import debounce from 'lodash/debounce'

export const WalletSdkProvider = 'WalletSdkProvider'

export default function (context: Context, inject: Function) {
  const walletConnectOptions = {
    projectId: '13c75e7d20888adc7e57cad417ad9ed8',
    metadata: {
      name: '.bit',
      description: 'Barrier-free DID for Every Community and Everyone',
      url: 'https://d.id',
      icons: ['https://d.id/favicon.png'],
    },
  }

  // @ts-expect-error
  const wagmiConfig = createConfig({
    chains: [ethereum, holesky, bsc, bscTestnet, polygon, polygonMumbai],
    transports: {
      [ethereum.id]: http(),
      [holesky.id]: http(),
      [bsc.id]: http(),
      [bscTestnet.id]: http(),
      [polygon.id]: http(),
      [polygonMumbai.id]: http(),
    },
    connectors: [injected(), walletConnect(walletConnectOptions)],
  })

  const _wallet = new Wallet({
    isTestNet: !isProdData,
    loggedInSelectAddress: false,
    wagmiConfig
  })

  const reloadPage = debounce(() => {
    window.location.reload()
  }, 1000, { leading: false })

  void context.store.dispatch(ME_KEYS.getConnectedAccount)

  _wallet.walletSDK?.context?.addEventListener('walletConnect', () => {
    console.log('wallet-sdk.ts: walletConnect')
    void context.store.dispatch(ME_KEYS.getConnectedAccount)
    reloadPage()
  })

  _wallet.walletSDK?.context?.addEventListener('walletDisconnect', () => {
    console.log('wallet-sdk.ts: walletDisconnect')
    void context.store.dispatch(ME_KEYS.getConnectedAccount)
  })

  _wallet.walletSDK?.context?.addEventListener('walletChange', () => {
    console.log('wallet-sdk.ts: walletChange')
    void context.store.dispatch(ME_KEYS.getConnectedAccount)
    reloadPage()
  })

  void _wallet.initWallet({ involution: false }).then(() => {
    void context.store.dispatch(ME_KEYS.getConnectedAccount)
  })

  inject('wallets', _wallet)

  // provide services for composition-api
  onGlobalSetup(() => {
    provide<Wallet>(WalletSdkProvider, _wallet)
  })
}
