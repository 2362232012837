import { Context } from '@nuxt/types'
import { AxiosError, AxiosResponse } from 'axios'
import errno, { isUnknownAPIBusinessError, getErrorName } from '~/constant/errno'

let upgradeNotified = false

class APIBusinessError extends Error {
  errorCode: number
  errorName: string
  errorMsg: string
  requestUrl: string
  fullData: AxiosResponse

  constructor (requestUrl: string, errorCode: number, errorMsg: string, fullData: AxiosResponse) {
    super(`the API ${requestUrl} has failed to response because of ${errorCode}: ${errorMsg}`)
    this.name = 'APIBusinessError'
    this.errorCode = errorCode
    this.errorName = getErrorName(errorCode) || 'Unknown'
    this.errorMsg = errorMsg
    this.requestUrl = requestUrl
    this.fullData = fullData
  }
}

export function onFulfilled (context: Context) {
  return (res: AxiosResponse) => {
    if (res.data) {
      if (res.data.err_no === errno.success) {
        return res.data.data
      }
      else if (res.data.err_no === errno.apiErrorCodeSystemUpgrade) {
        if (!upgradeNotified && process.client) {
          context.app.$serviceUpgradeTips()
        }
        upgradeNotified = true
      }
      else {
        if (isUnknownAPIBusinessError(res.data.err_no)) {
          const apiBusinessError = new APIBusinessError(res.request.responseURL, res.data.err_no, res.data.err_msg, res)
          context.$sentry.captureException(apiBusinessError)
        }
        const serverError: any = new Error(res.data.err_msg)
        serverError.code = res.data.err_no
        throw serverError
      }
    }
    else {
      const serverError: any = new Error(res.statusText)
      serverError.code = res.status
      throw serverError
    }
  }
}

export function onRejected () {
  return (err: AxiosError) => {
    const serverError: any = new Error(err?.response?.statusText || err.message)
    serverError.code = err?.response?.status || errno.networkError
    throw serverError
  }
}
